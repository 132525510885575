import React, { useState }  from 'react';

import { useSearchParams } from "react-router-dom";

import BreadCrumbs from "../BreadCrumbs/BreadCrumbs";
import NewRefuel from "./NewRefuel"; 
import UpdateRefuel from "./UpdateRefuel"; 
import { Link } from "react-router-dom";



function Refuel({ guid }) {
	



	let [searchParams] = useSearchParams();
	const id = searchParams.get('id');
	const isNew = id === null;

	


	return (
		<>
		    <BreadCrumbs path={[{link: "/", title:"Hem"}]} title="Tanka" />
			<br/> 
			{isNew ? <NewRefuel /> : <UpdateRefuel id={parseInt(id)} /> }
			<Link  className="linkbutton" to="/refuel/list">Lista tankningar</Link>
		</>
	);
}



export default Refuel;