
import React, { useState }  from 'react';

import axios from 'axios';
import ClipLoader from "react-spinners/ClipLoader";
import BreadCrumbs from "../BreadCrumbs/BreadCrumbs";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLineChart } from '@fortawesome/free-solid-svg-icons'; // chart-line  <i class="fa-solid fa-chart-line"></i>
import { Link } from "react-router-dom";



function SunChartDataInput({ server, apikey}) {

	const [data, setData] = useState('');
	const [saving, setSaving] = useState(false);
	const [message, setMessage] = useState('');
	
	const onSaveForm = (event) => {		
		if (!data) {
			setMessage('Data är tomt.');
			return;
		}
      // Create an object of formData 
      const formData = new FormData(); 
     
      // Update the formData object 
	  formData.append("data", data); 
	  formData.append("plant", 'orjan'); 
     
      // Request made to the backend api 
      // Send formData object 
      axios.post(server+"sunchart/api?apikey="+apikey, formData)
		.then(response => {
			setMessage(response.data); 
			setSaving(false); 
			setData(null);			
		});
	  setSaving(true); 
	}
	
	if (saving) {
		return (
			<ClipLoader loading={saving} size={50} />
		);
	}
	
	const showMessage = () => {
		if (!message) {
			return null;
		}
		return (<div className="errorMessage">{message}</div>);
	}
	

	return (
		<>
			<BreadCrumbs path={[{link: "/", title:"Hem"}]} title="Solenergi" />
			<br/>
			<Link to="/sunchart/stats"><FontAwesomeIcon className="nobreak" icon={faLineChart} /></Link>
			
			<br/>
			{showMessage()}
			<label htmlFor="data"><b>Data</b></label><br/>
		    <textarea name="data" cols="45" rows="15" onChange={(event) => {setData(event.target.value);setMessage('')}} value={data}></textarea><br/>
			<button type="button" name="saveSunData" onClick={onSaveForm}>Spara</button>
		</>
	);
}

export default SunChartDataInput;