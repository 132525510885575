import React, { useState }  from 'react';
import { Link } from "react-router-dom";

function WeightInputForm({ metric, onSave,  setSelectedMetric }) {

	const [correctOption, setCorrectOption] = useState(metric.correctOption);
	const [errorMsg, setErrorMsg] = useState('');
	
	const [weight, setWeight] = useState(metric.weight);
	


	const onSaveForm = (event) => {		
		const date = event.target.form[0].value;
		const weight = event.target.form[1].value;
		
		let error = '';
		
		if (!date) {
			error += " Datum saknas.";
		}
		if (!weight) {
			error += " Vikt saknas.";
		}

		if (!error) {
			onSave( { weight: parseFloat(weight), date: date });
		}
		setErrorMsg(error);
	}
	
	const handleWeightChange = (event) => {	
		//setWeight(event.target.value);
		metric.weight = event.target.value;
		setSelectedMetric(metric);
	}
	
	const handleDateChange = (event) => {	
		metric.date = event.target.value;
		setSelectedMetric(metric);
	}
	
	const dateField = () => {	
		return (<><input type="date" name="date"  value={metric.date}  onChange={handleDateChange} readOnly={metric.id} /></>);
	}

	return (
		
		<div className="weightinputform">
		   <span className="errormessage">{errorMsg}</span>
			<form>
				<p>
				Datum:<br/>
					{dateField()}					
				</p>
				
				<p>
				Vikt:<br/>
					<input type="number" name="weight" value={metric.weight } onChange={handleWeightChange}/>
				</p>
				
				
				{metric.id ? <Link className="linkbutton" to="/weight" onClick={() => {setSelectedMetric({})}}>Ny</Link> : ''}&nbsp;
				<button type="button" name="saveMetric" onClick={onSaveForm}>Spara</button>
			</form>
		</div>
	);
}

export default WeightInputForm;