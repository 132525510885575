import React, { useState }  from 'react';

import {
	useQuery,
	gql,
	useMutation
} from "@apollo/client";

import ClipLoader from "react-spinners/ClipLoader";

import RefuelForm from './RefuelForm';

let sentData = null;

function UpdateRefuel({ id }) {
	
	const [saving, setSaving] = useState(false);
	const [refuel, setRefuel] = useState();

	const REFUEL_QUERY = gql`
		     query getRefuel($id: Int!)  {
			  getRefuel(id: $id) {
				  	id
				    parent {
				      ... on object_Vehicle {
				        id
				      }
				    }
				    date
				    mileage {
				      value
				    }
				    price {
				      value
				    }
				    volume {
				      value
				    }
				    fuel
				    station {
				      ... on object_FuelStation {
				        id
				      }
				    }
				    Comment
			  }
			}
		    `;

	const UPDATE_REFUEL_MUTATION = gql`
	  mutation updateRefuelMutation($id: ID!, $date: String!, $mileage: Int!, $price: Float!, $volume: Float!, $fuel: String!, $station: ID!, $comment: String) {
	  updateRefuelMutation(id: $id, 
	    input: {date: $date, mileage: $mileage, price: $price, volume: $volume, 
	      fuel: $fuel, station: $station, comment: $comment}) {
	    success
	    message
	    id
		price
	  } 
	}
	`;
	
	const  { loading, error, data } = useQuery(REFUEL_QUERY, {
			variables: { id: id },
//			fetchPolicy: "no-cache",
			onCompleted: data => {
				if (data.getRefuel) {
					let theRefuel = { 	
								id: parseInt(data.getRefuel.id),
								vehicle: data.getRefuel.parent.id,
						        date: data.getRefuel.date.substring(0, 10),
						        mileage: data.getRefuel.mileage.value,
								price: data.getRefuel.price.value,
						        volume: data.getRefuel.volume.value,
						        fuel: data.getRefuel.fuel,
								station: data.getRefuel.station.id,
								Comment: data.getRefuel.Comment};
					setRefuel(theRefuel);
				}
			}
		});
		
	const [callUpdateRefuel, { error: gError }] = useMutation(UPDATE_REFUEL_MUTATION, { 
		variables: { }, 
		onCompleted: data => {
			console.log(data.updateRefuelMutation.id+' '+data.updateRefuelMutation.message);		
			setSaving(false);			
			sentData.price = data.updateRefuelMutation.price;
			setRefuel(sentData);
		},
		onError: data => {
			console.log('Updating refuel failed');			
		}
	});
	

		
	if (loading) {
		console.log('loading refuel');
		return (
			<div>
				<ClipLoader loading={loading} size={150} />
			</div>
		);
	}
	if (error) {
		console.log('error loading refuel');
		return `Error! ${error}`;
	}
	if (gError) {
		console.log('error updating refuel');
		return `Error! ${gError}`;
	}
	if (!data || !data.getRefuel) {
		return (
			<div>
				Hittade ingen tankning med givet id.
			</div>
		);
	}


	const onSave = (refuel) => {
		setSaving(true);
		//setRefuel(refuel);
		sentData = refuel;
		callUpdateRefuel({ variables : { id: parseInt(refuel.id), date: refuel.date, mileage: refuel.mileage,
		price: parseFloat(refuel.price), volume: parseFloat(refuel.volume), fuel: refuel.fuel, station: parseInt(refuel.station), comment: refuel.comment}});
	}
	
	if (saving) {
		return (
			<ClipLoader loading={saving} size={150} />
		);
	}


	return (
		<>
			<RefuelForm refuel={refuel} onSave={onSave} />
			
			<ClipLoader loading={saving} size={150} />
		</>
	);
}

export default UpdateRefuel;