import React,{ useState }  from 'react';
import Main from './Main';
import SignIn from './SignIn';
import Navbar from './Navbar/Navbar';
import './App.css';

const API_TOKEN = 'apiToken'; 
const PWD = 'pwd';
const SERVER = 'server';


 
function App() {


 	const token = localStorage.getItem(API_TOKEN);
	const pwd = localStorage.getItem(PWD);
	const server = localStorage.getItem(SERVER);
	const [isSignedIn, setIsSignedIn] = useState(token);
	const [pwdPath, setPwdPath] = useState(pwd);
	const [serverUrl, setServerUrl] = useState(server);
	const [version, setVersion] = useState(localStorage.getItem('app_version'));
	
	const login = (token) => {
		localStorage.setItem(API_TOKEN, token);
		setIsSignedIn(token);
	}
	
	const logout = () => {
		const doIt = window.confirm("Om du gör detta kommer du behöva en inbjudningslänk, eller kunna fylla i api-nyckeln och personlig nyckel, för att kunna fortsätta använda appen.");
		if (doIt) {
			localStorage.removeItem(API_TOKEN);
			localStorage.removeItem(PWD);
			setIsSignedIn(0);
			setPwdPath('');
		}
		
		return doIt;
	}
	

	
	const setPwd = (pwd) => {
		localStorage.setItem(PWD, pwd);
		setPwdPath(pwd);
	}
	
	const setServer = (server) => {
		localStorage.setItem(SERVER, server);
		setServerUrl(server);
	}
	
	const toInvite = window.location.pathname === '/invite';

    return (    
       <div className="App">

	<Navbar logout={logout} version={version}/>



			{isSignedIn || toInvite ? <Main setPwd={setPwd} setIsSignedIn={login} token={token} guid={pwdPath} server={serverUrl} setServer={setServer}  version={version} setVersion={setVersion}/> : <SignIn setIsSignedIn={login} setPwd={setPwd}  token={token} pwd={pwd} server={server} setServer={setServer}/>}
       </div>
 
    );
  }

export default App;    
