import React, { useState }  from 'react';
import ClipLoader from "react-spinners/ClipLoader";

import {
	useQuery,
	gql,
	useMutation
} from "@apollo/client";

import StationSelector, { setClosestStation } from "./StationSelector";
import NewFuelStation, {show} from './NewFuelStation';
import { Link } from "react-router-dom";

const emptyBaseData = {fueltypes : [], stationbrands: [], stations : [], vehicles: []};

const SETUP_QUERY = gql`
	     query getObjectFolder($path: String!)  {
		  getObjectFolder(fullpath: $path) {
			id
		    children {
		      ... on object_folder {
		        id
		        key
		        children {
					... on object_Brand {
			            id
			            name
			            key
			          }
		          ... on object_FuelType {
		            id
					key
		            name
		          }
		          ... on object_FuelStation {
		            id
		            active
		            brand
		            location {
		              longitude
		              latitude
		            }
		            name
		            
		          }
	          ... on object_Vehicle {
	            id
	            brand
	            model
	            preferredFuel
	            registrationNo
	          }
        }
      }
    }
  }
}

	    `;


function RefuelForm({refuel, onSave}) {
	const emptyRefuel = {date: new Date().toISOString().substring(0, 10), mileage: '', price: '', volume: '', fuel: '', comment: '', station: 0, vehicle: 0};
	refuel = refuel ? refuel : emptyRefuel;
	const [state, setState] = useState(refuel);
	const [baseData, setBaseData] = useState(emptyBaseData);
	const [errorMsg, setErrorMsg] = useState('');
	const [viewNewStationForm, setViewNewStationForm] = useState(false);
	const path = '/refuel';
	
	
	const handleStateChange = (property, value) => {
		const rf = {...state};	
		rf[property] = value;
		setState(rf);
	}
	
	const setStationId = (stationId) => {
		//handleStateChange('station', stationId);
		//refuel.station = stationId;
		handleStateChange('station', stationId);
		//refuel.station = stationId;
		//setState({...refuel});
	}
	
	const  { loading, error, data } = useQuery(SETUP_QUERY, {
			variables: { path: path },
//			fetchPolicy: "no-cache",
			onCompleted: data => {
				if (data.getObjectFolder) {
					let ids = {};
					data.getObjectFolder.children.forEach(e => {
						baseData[e.key] = e.children.map(e => {return e});
						ids[e.key] = parseInt(e.id);
					});
					baseData.stations = baseData.stations.map(e => {
						let s = {...e}; 
						s.brand = e.brand.substring(e.brand.lastIndexOf('/') + 1); 
						return s;
					});
					if (!state.station) {
						setClosestStation(baseData.stations, setStationId);
					}
					if (!state.vehicle) {
						const v = baseData.vehicles.find(v => {return v.registrationNo === 'DHK747'});
						refuel.vehicle = v ? v.id : 0;
						refuel.fuel = v ? v.preferredFuel : '';
						setState(refuel);
					}
					baseData.stations = baseData.stations.sort((a, b) => {return a.name.localeCompare(b.name)});
					baseData.ids = ids;	
					setBaseData(baseData);				
				}
			}
		});

	if (loading) {
		console.log('loading base data');
		return (
			<div>
				<ClipLoader loading={loading} size={150} />
			</div>
		);
	}
	if (error) {
		console.log('error loading base data');
		return `Error! ${error}`;
	}
	
	
	const handleDateChange = (event) => {	
		handleStateChange('date', event.target.value);
		//refuel.date = event.target.value;
		//setState({...refuel});
		//setSelectedMetric(metric);
	}
	const changeVehicle = (event) => {
		console.log('selected Vehicle '+event.target.value);
		handleStateChange('vehicle', event.target.value);
		//refuel.vehicle = event.target.value;
		//setState({...refuel});
		//setSelectedUnitId(event.target.value);
	}
	
	
	const handleStationChange = (event) => {
		console.log('selected Station '+event.target.value);
		setStationId(event.target.value);
	}
	const changeFuelType = (event) => {
		console.log('selected FuelType '+event.target.value);
		handleStateChange('fuel', event.target.value);
		//refuel.fuel = event.target.value;
		//setState({...refuel});
		//setSelectedUnitId(event.target.value);
	}
	const handleMileageChange = (event) => {	
		handleStateChange('mileage', parseInt(event.target.value));
		//refuel.mileage = parseInt(event.target.value);
		//setState({...refuel});
		//setSelectedMetric(metric);
	}
	const handleVolumeChange = (event) => {	
		handleStateChange('volume', event.target.value);
		//refuel.volume = parseFloat(event.target.value);
		//setState({...refuel});
	}
	const handlePriceChange = (event) => {	
		handleStateChange('price', event.target.value);
		//refuel.price = parseFloat(event.target.value);
		//setState({...refuel});
	}
	
	const handleCommentChange = (event) => {	
		handleStateChange('Comment', event.target.value);
		//refuel.Comment = event.target.value;
		//setState({...refuel});
	}
	const onSaveForm = (event) => {		

		let error = '';
		
		if (!state.date) {
			error += " Datum saknas.";
		}
		if (!state.station) {
			error += " Station saknas.";
		}
		if (!state.mileage) {
			error += " Mätarställning saknas.";
		}
		if (!state.volume) {
			error += " Volym saknas.";
		}
		if (!state.price) {
			error += " Pris saknas.";
		}

		if (!error) {
			onSave( state );
		}
		setErrorMsg(error);
	}
	
	const setNewStation = (station) => {
		const newBaseData = {...baseData};
		newBaseData.stations.push(station);
		setBaseData(newBaseData);
		setStationId(station.id);
	}

	return (
		<>
		<NewFuelStation baseData={baseData} visible={viewNewStationForm} setVisible={setViewNewStationForm} setNewStation={setNewStation}/>
		<span className="errorMessage">{errorMsg}</span>
		<table>
			<tbody>
		  		<tr><td>Datum</td><td><input type="date" name="date"  value={state.date}  onChange={handleDateChange} /></td></tr>
				<tr><td>Fordon</td><td>
					<select value={state.vehicle} onChange={changeVehicle}>
				 	 <option value="0">Välj</option>
					 {baseData.vehicles.map(v => (<option key={v.id} value={v.id} >{v.brand} {v.model}</option>))}
					</select>
				</td></tr>
				<tr><td>Station</td><td>
					<StationSelector baseData={baseData} state={state} handleStationChange={handleStationChange} />&nbsp;
					 <button type="button" onClick={() => {setViewNewStationForm(true)}}>Ny</button>
				</td></tr>				
				<tr><td>Bränsle</td><td>
					<select value={state.fuel} onChange={changeFuelType}>
				 	 <option value="0">Välj</option>
					 {baseData.fueltypes.map(f => (<option key={f.id} value={f.key} >{f.name}</option>))}
					</select>
				</td></tr>
				<tr><td>Pris</td><td><input type="number" name="price"  value={state.price}  onChange={handlePriceChange} step="any"/></td></tr>
				<tr><td>Volym</td><td><input type="number" name="volume"  value={state.volume}  onChange={handleVolumeChange} step="any"/></td></tr>
				<tr><td>Mätaraställning</td><td><input type="number" name="mileage"  value={state.mileage}  onChange={handleMileageChange} step="1"/></td></tr>
				<tr><td>Kommentar</td><td><textarea name="comment" cols="25" rows="5" onChange={handleCommentChange} >{state.Comment}</textarea></td></tr>
				<tr><td>{state.id ? <Link className="linkbutton" to="/refuel" onClick={() => {setState(emptyRefuel)}}>Ny</Link> : ''}</td>
				     <td align="right"><button type="button" name="saveRefuel" onClick={onSaveForm}>Spara</button></td></tr>
			</tbody>
		</table>
		</>
	);
}

export default RefuelForm;