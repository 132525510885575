import React, { useState }  from 'react';

import {
	gql,
	useMutation
} from "@apollo/client";

import ClipLoader from "react-spinners/ClipLoader";

import RefuelForm from './RefuelForm';
import { useNavigate } from "react-router-dom";

const CREATE_REFUEL_MUTATION = gql`
	mutation createRefuelMutation($vehicle: ID!, $date: String!, $mileage: Int!, $price: Float!, $volume: Float!, $fuel: String!, $station: ID!, $comment: String) {
	  createRefuelMutation(parent: $vehicle, 
	    input: {date: $date, mileage: $mileage, price: $price, volume: $volume, 
	      fuel: $fuel, station: $station, comment: $comment}) {
	    success
	    message
	    id
	  } 
	}
  `;

function NewRefuel() {

	const [saving, setSaving] = useState(false);



	//const refuel = {date: new Date().toISOString().substring(0, 10), mileage: '', price: '', volume: '', fuel: '', comment: '', station: 0, vehicle: 0};
	
	
	const navigate = useNavigate();

		
	const [callCreateRefuel, { error }] = useMutation(CREATE_REFUEL_MUTATION, { 
		variables: { }, 
		onCompleted: data => {	
			navigate( '?id='+data.createRefuelMutation.id);
		},
		onError: data => {
			console.log('Creating refuel failed');	
		}
	});
	

		
	if (error) {
		return `Error! ${error}`;
	}


	const onSave = (refuel) => {
		setSaving(true);
		callCreateRefuel({ variables : { vehicle: parseInt(refuel.vehicle), date: refuel.date, mileage: refuel.mileage,
		price: parseFloat(refuel.price), volume: parseFloat(refuel.volume), fuel: refuel.fuel, station: parseInt(refuel.station), comment: refuel.comment}});
	}
	
	if (saving) {
		return (
			<ClipLoader loading={saving} size={150} />
		);
	}


	return (
		<>
			<RefuelForm onSave={onSave}/>
			
		</>
	);
}

export default NewRefuel;




