import React from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import './Navbar.css';


function Navbar( {logout, version }) {
	
		/* Toggle between showing and hiding the navigation menu links when the user clicks on the hamburger menu / bar icon */
	const toggleMenu = () => {
		var x = document.getElementById("myLinks");
		  if (x.style.display === "block") {
		    x.style.display = "none";
		  } else {
		    x.style.display = "block";
		  }
	}
	
	const navLogout = () => {
		const doIt = logout();
		if (doIt) {
			toggleMenu();
		}
		
		return doIt;
	}
	
	return (
		<>
		<div className ="topnav">
    <span className="menuicon" onClick={toggleMenu}><FontAwesomeIcon className="nobreak" icon={faBars} /></span><span className="titlelogocontainer" ><Link className="nobreak" to="/"><img className="titlelogo" src="/logo192.png" width="35" height="35" alt="Home app"/></Link></span>

	
	  <div id="myLinks">
		<Link onClick={toggleMenu} to="/">Hem</Link>
		<Link onClick={toggleMenu} to="/weight">Vikt</Link>
		<Link onClick={toggleMenu} to="/refuel">Tanka</Link>
		<Link onClick={toggleMenu} to="/sunchart">Solenergi</Link>
		<Link onClick={toggleMenu} to="/signin">Inställningar</Link>		
		<Link onClick={navLogout} to="/">Logga ut</Link>
	  </div>
  <span className="version">{version}</span>

</div>

</>
	);
}

export default Navbar;
	