import React, { useState }  from 'react';

import {
	useQuery,
	gql,
	useMutation
} from "@apollo/client";

import ClipLoader from "react-spinners/ClipLoader";
import { useSearchParams } from "react-router-dom";

import WeightInputForm from './WeightInputForm';


function UpdateWeightInput({ guid, metric, setSelectedMetric }) {
	


	const [saving, setSaving] = useState(false);

	const METRIC_QUERY = gql`
		     query getHealthMetrics($path: String!)  {
			  getHealthMetrics(fullpath: $path) {
				 id
				 key
			     date
			     weight
			  }
			}
		    `;

	const UPDATE_METRIC_MUTATION = gql`
	  mutation updateHealthMetrics($id: Int!, $weight: Float!, $date: String!) {
	  	updateHealthMetrics(id: $id, input: {date: $date, weight: $weight }) {
		  success
	      message 
		  output {
			id
			key
			weight
			date
		  }
		}
	 }
	`;

	let [searchParams] = useSearchParams();
	const metricKey = searchParams.get('metric');
	const newMetric = metricKey === null;
	
	const path = "/home/profiles/"+guid+"/healthmetrics/"+metricKey.substring(0,4) + "/" + metricKey.substring(5,7) + "/" + metricKey;
	
	const  { loading, error, data } = useQuery(METRIC_QUERY, {
			variables: { path: path },
//			fetchPolicy: "no-cache",
			onCompleted: data => {
				if (data.getHealthMetrics) {
					metric = { 	id: parseInt(data.getHealthMetrics.id),
								key: data.getHealthMetrics.key,
						        date: data.getHealthMetrics.date.substring(0, 10),
						        weight: data.getHealthMetrics.weight};
					setSelectedMetric(metric);
				}
			}
		});
		
	const [callUpdateMetric, { error: gError }] = useMutation(UPDATE_METRIC_MUTATION, { 
		variables: { }, 
		onCompleted: data => {
			console.log(data.updateHealthMetrics.output.id+' '+data.updateHealthMetrics.message);		
			metric = {  id: parseInt(data.updateHealthMetrics.output.id),
						key: data.updateHealthMetrics.output.key,
				        date: data.updateHealthMetrics.output.date.substring(0, 10),
				        weight: data.updateHealthMetrics.output.weight};
			setSaving(false);			
			setSelectedMetric(metric);
		},
		onError: data => {
			console.log('Updating metric failed');			
		}
	});
	
	if (newMetric) {
		metric = {  key: '',
			        date: '',
			        weight: '',
				 }
	}

		
	if (loading) {
		console.log('loading metric');
		metric = null;
		return (
			<div>
				<ClipLoader loading={loading} size={150} />
			</div>
		);
	}
	if (error) {
		console.log('error loading metric');
		return `Error! ${error}`;
	}
	if (gError) {
		console.log('error updating metric');
		return `Error! ${gError}`;
	}
	if (!data || !data.getHealthMetrics) {
		return (
			<div>
				Hittade ingen metric med given nyckel.
			</div>
		);
	}
	
	if (!metric && !saving) {
		metric = { id: parseInt(data.getHealthMetrics.id),
					key: data.getHealthMetrics.key,
			        date: data.getHealthMetrics.date.substring(0, 10),
			        weight: data.getHealthMetrics.weight};

	}


	const onSave = (metricData) => {
		setSaving(true);
		callUpdateMetric({ variables : { id: metric.id, weight: metricData.weight, date: metricData.date }});
	}
	
	if (saving) {
		return (
			<ClipLoader loading={saving} size={150} />
		);
	}


	return (
		<>
			<WeightInputForm metric={metric} onSave={onSave}  setSelectedMetric={setSelectedMetric} />
			
			<ClipLoader loading={saving} size={150} />
		</>
	);
}

export default UpdateWeightInput;