import React from 'react';
import { Link } from "react-router-dom";

function StartPage({guid}) {
	
	

	return (
		
		<div>
		
		<h1 className="center">Välkommen hem</h1>
			
			<ul className="startpagelist">
				<li><Link to="/weight">Vikt</Link></li>
				<li><Link to="/refuel">Tanka</Link></li>
				<li><Link to="/sunchart">Solenergi</Link></li>
			</ul>
       		
		</div>
	);
}

export default StartPage;