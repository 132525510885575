import React, { useState }  from 'react';

import {
	gql,
	useMutation
} from "@apollo/client";

import ClipLoader from "react-spinners/ClipLoader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'; 
import './Refuel.css';
import { Link } from "react-router-dom";

const CREATE_STATION_MUTATION = gql`
	mutation createRefuelMutation($parent: Int!, $name: String!, $longitude: Float!, $latitude: Float!, $brand: String) {
	  createFuelStation(parentId: $parent, key: $name, 
					  input: {name: $name, active: true, brand: $brand, 
					    location: {longitude: $longitude, latitude: $latitude}}){
					    success
					    message
					    output {
					      id
					    }
					  }
	}
  `;

const EMPTY_FORM = {name: '', brand: '', latitude: '', longitude: ''}; 


function NewFuelStation({baseData, visible, setVisible, setNewStation}) {
		

	const [saving, setSaving] = useState(false);
	const [state, setState] = useState(EMPTY_FORM);
	const [errorMsg, setErrorMsg] = useState('');
	
	const hide = () => {
		setVisible(false);
		setState(EMPTY_FORM);
		setErrorMsg('');
	}

		
	const [callCreateStation, { error }] = useMutation(CREATE_STATION_MUTATION, { 
		variables: { }, 
		onCompleted: data => {				
			setSaving(false);			
			const rf = {...state};
			rf.id = data.createFuelStation.output.id;
			setNewStation(rf);
			hide();
		},
		onError: data => {
			setSaving(false);
			console.log('Creating STATION failed '+error);	
			setErrorMsg('Misslyckades att skapa stationen.');
		}
	});
	
	if (!visible) {
		return ``;
	}
	
	if (!state.longitude && navigator.geolocation) {
		navigator.geolocation.getCurrentPosition(

		function(position) {
			const rf = {...state};	
			rf.latitude = position.coords.latitude;
			rf.longitude = position.coords.longitude;
			setState(rf);
		
		});
	}


	const onSave = (station) => {
		setSaving(true);
		callCreateStation({ variables : { parent: baseData.ids['stations'], name: station.name, brand: station.brand,
		latitude: station.latitude ? parseFloat(station.latitude):0, longitude: station.longitude ? parseFloat(station.longitude) : 0}});
	}
	
	const onSaveForm = (event) => {
		let error = '';
		
		if (!state.name) {
			error += " Namn saknas.";
		}
		if (!state.brand) {
			error += " Stationstyp saknas.";
		}

		if (!error) {
			onSave( state );
		}
		else {
			setErrorMsg(error);
		}

	}
	
	if (saving) {
		return (
			<ClipLoader loading={saving} size={150} />
		);
	}
	
	const handleStateChange = (property, value) => {
		const rf = {...state};	
		rf[property] = value;
		setState(rf);
	}


	return (
		<>
	
			<div className="form-popup" id="myForm">			  
			  <form action="/action_page.php" className="form-container">
 				<Link className="closelink" onClick={() => {hide()}}><FontAwesomeIcon  icon={faXmark} /></Link>
				<span className="errorMessage">{errorMsg}</span>
			    <h1>Ny station</h1>
			    
			    <label htmlFor="name"><b>Namn</b></label>
			    <input type="text" placeholder="Ange namn" name="name" value={state.name} required onChange={(event) => handleStateChange('name', event.target.value)} />

			
			    <label htmlFor="brand"><b>Stationtyp</b></label>
			    <select value={state.brand} onChange={(event) => handleStateChange('brand', event.target.value)}>
				 	 <option value="0">Välj</option>
					 {baseData.stationbrands.map(b => (<option key={b.id} value={b.name} >{b.name}</option>))}
				</select>
				
				<label htmlFor="longitude"><b>Longitude</b></label>
			    <input type="text" placeholder="Ange longitude" name="longitude" value={state.longitude} onChange={(event) => handleStateChange('longitude', event.target.value)} />

				<label htmlFor="latitud"><b>Latitud</b></label>
			    <input type="text" placeholder="Ange latitud" name="latitude" value={state.latitude}  onChange={(event) => handleStateChange('latitude', event.target.value)} />

			    <button type="button" className="btn" name="saveRefuel" onClick={onSaveForm}>Spara</button>
			    
			  </form>
			</div>
			
</>
	);
}

export default NewFuelStation;



