import React, { useState }  from 'react';

import {
	gql,
	useMutation
} from "@apollo/client";

import ClipLoader from "react-spinners/ClipLoader";
import { useSearchParams } from "react-router-dom";

import WeightInputForm from './WeightInputForm';
import { useNavigate } from "react-router-dom";



function NewWheightInput({ guid, setSelectedMetric, metric }) {

	const [saving, setSaving] = useState(false);

	const CREATE_METRIC_MUTATION = gql`
	 mutation createHealthMetrics($key: String!, $path: String!, $weight: Float!, $date: String!) {
	  createHealthMetrics(key: $key, published: true, path: $path, input: {weight: $weight, date: $date}) {
	    success
	    message
	    output {
	     	id
		 	key
        	weight
        	date
	    }
	  }
	}
  `;


	//let metric = {date: new Date().toISOString().substring(0, 10), weight:''};
	if (!metric.hasOwnProperty('id') || metric.id == null) {
		setSelectedMetric({id:0, date: new Date().toISOString().substring(0, 10), weight: ''});
	}
	
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const nextPage = searchParams.get('source') || '/weight';
	
	const path = "/home/profiles/"+guid+"/healthmetrics/";
	
		
	const [callCreateMetric, { error }] = useMutation(CREATE_METRIC_MUTATION, { 
		variables: { }, 
		onCompleted: data => {	
			metric = data.createHealthMetrics.output;
			navigate( nextPage+'?added=1&metric='+metric.key);
		},
		onError: data => {
			console.log('Creating metric failed');			
		}
	});
	

		
	if (error) {
		return `Error! ${error}`;
	}


	const onSave = (metric) => {
		setSaving(true);
		callCreateMetric({ variables : { weight: metric.weight, date: metric.date, key: metric.date,
		path: path +  metric.date.substring(0,4) + "/" + metric.date.substring(5,7)}});
	}
	
	if (saving) {
		return (
			<ClipLoader loading={saving} size={150} />
		);
	}


	return (
		<>
			<WeightInputForm metric={metric} onSave={onSave} setSelectedMetric={setSelectedMetric} />
			
			<ClipLoader loading={saving} size={150} />
		</>
	);
}

export default NewWheightInput;