import React, { useState }  from 'react';

import {
	useQuery,
	gql
} from "@apollo/client";

import BreadCrumbs from "../BreadCrumbs/BreadCrumbs";
import moment from 'moment';
import './WeightChart.css';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { Line } from 'react-chartjs-2';


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

	const METRICS_QUERY = gql`
		     query getHealthMetricsListing($filter: String!)  {
			  getHealthMetricsListing(filter: $filter, sortBy: "date", sortOrder: "ASC") {
				edges {
			      node {
			        id
					key
			        date
			        weight
			      }
			    }
			  }
			}
		    `;

const charts = {
	day: {name: 'Dag',
		  dateFormat: 'YY-MM-DD'},
	week: {name: 'Vecka',
		  dateFormat: 'YY-WW'},
	month: {name: 'Månad',
		  dateFormat: 'YY-MM'},
}


function WeightChart({ guid }) {
	
	//new Date().toISOString().substring(0, 10)
	let metrics = [];	
	const date = new Date();
	const todayString = date.toISOString().substring(0, 10);
	
	
	const [type, setType] = useState('week');
	
	if (type === 'day')
		date.setMonth(date.getMonth() - 3);
	if (type === 'week') {
		date.setMonth(date.getMonth() - 12);
		date.setDate(1);
	}
	if (type === 'month') {
		date.setMonth(date.getMonth() - 72);
		date.setDate(1);
	}
	
	const timeBackString = date.toISOString().substring(0, 10);
	
	const [from, setFrom] = useState(timeBackString);
	const [to, setTo] = useState(todayString);
	
	
	const path = "/home/profiles/"+guid+"/healthmetrics/%";
	const filter  =  '{"o_path": {"$like" : "'+path+'"}, "$and": [{"date" : {"$gte" : "'+from+'"}}, {"date" : {"$lte" : "'+to+'"}}]}';
	// "{\"o_path\": {\"$like\" : \"/home/profiles/Örjan/healthmetrics/%\"}, \"$and\" : [{\"date\" : {\"$lte\" : \"2022-09-26\"}}, {\"date\" : {\"$gte\" : \"2022-09-23\"}}]}"
	//const filter  = '{"o_path": {"$like" : "'+path+'"}}';
	const { loading, error, data, refetch } = useQuery(METRICS_QUERY, {
		variables: { filter:  filter},
		onCompleted: data => {

		}
	});
	
	
	
	
	if (data) {
		metrics = data.getHealthMetricsListing.edges.map(metric => {return {id: metric.node.id, key: metric.node.key, date: metric.node.date.substring(0,10), weight: metric.node.weight };});
		
		//metrics.sort((a,b) => a.key < b.key ? 1 : a.key > b.key ? -1 : 0);
		//metrics = metrics.filter(m => m.date >= from && m.date <= to);
	}
	
	
let chart =  {"type":"line","options":{"responsive":true,"title":{"display":true,"text":"Viktutveckling - dagsvy"},
"tooltips":{"mode":"index","intersect":false},"hover":{"mode":"nearest","intersect":true},
"scales":{"xAxes":[{"display":true,"scaleLabel":{"display":true,"labelString":"Datum"},"stacked":false}],
          "yAxes":[{"id":"yAxis","display":true,"position":"left","ticks":{"beginAtZero":false,"max":78.3,"min":70.8},
"scaleLabel":{"display":true,"labelString":"Vikt"},"stacked":false}]}},"data":{"labels":["22-06-27","22-06-28","22-06-29","22-06-30","22-07-01","22-07-21","22-07-22","22-07-23","22-07-24","22-08-01","22-08-03","22-08-04","22-08-05","22-08-07","22-08-08","22-08-09","22-08-10","22-08-12","22-08-13","22-08-14","22-08-15","22-08-16","22-08-17","22-08-18","22-08-19","22-08-20","22-08-21","22-08-22","22-08-23","22-08-24","22-08-25","22-08-26","22-08-30","22-09-01","22-09-02","22-09-04","22-09-05","22-09-06","22-09-07","22-09-08","22-09-09","22-09-10","22-09-12","22-09-13","22-09-14","22-09-15","22-09-16","22-09-17","22-09-19","22-09-20","22-09-21","22-09-22","22-09-23","22-09-25","22-09-26"],"datasets":[{"yAxisID":"yAxis","label":"\u00d6rjan","data":[74.4,73.8,74.1,74.6,73.7,75.6,75.6,76,76.3,76.3,75.9,75.8,75.6,76,75.7,75.1,74.8,75.2,74.6,74.7,75.2,74.5,75.3,75,74.7,75.5,75.5,75.1,75.2,74.6,74.1,74.8,74.3,75.2,74.5,74.5,75,74.5,73.6,73.5,73.8,73.7,73.7,73.3,72.8,73.1,73.1,73.2,73.9,73.2,73.2,73.3,73.2,73.7,74],
"backgroundColor":"#e74c3c","borderColor":"#e74c3c","borderWidth":1,"fill":false},
{"yAxisID":"yAxis","label":"Rullande medel 7 dgr","data":[74.4,74.1,74.1,74.23,74.12,74.37,74.54,74.77,75.13,75.44,75.63,75.93,75.93,75.99,75.94,75.77,75.56,75.46,75.29,75.16,75.04,74.87,74.9,74.93,74.86,74.99,75.1,75.09,75.19,75.09,74.96,74.97,74.8,74.76,74.67,74.57,74.63,74.69,74.51,74.4,74.2,74.09,73.97,73.73,73.49,73.41,73.36,73.27,73.3,73.23,73.21,73.29,73.3,73.39,73.5],
"backgroundColor":"#af7ac5","borderColor":"#af7ac5","borderWidth":1,"fill":false}]}};

/*
scales:{xAxes:[{display:true,scaleLabel:{display:true,labelString:"Datum"},stacked:false}],
          yAxes:[{id:yAxis,display:true,position:"left",ticks:{beginAtZero:false,max:78.3,min:70.8},
scaleLabel:{display:true,labelString:"Vikt"},stacked:false}]}
*/
 const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Viktutveckling',
    },
  },

};

//const labels = metrics.map(metric => {return metric.date}); 
//const values = metrics.map(metric => {return metric.weight});

const metricSums = {};

const getLabel = (dateString) => {	
		const d = new Date(dateString);
		const typeData = charts[type];
		if (!typeData) {
			return '';
		}
		if (type == 'week') {
			const w = moment(d).isoWeek();
			const dayfix = w == 1 ? (7-d.getDay())%7 : -1*(d.getDay()+6)%7; // first day of week if not week 1, else last day of week, to get correct year
			d.setDate(d.getDate() + dayfix);
		}
		
		return moment(d).format(typeData.dateFormat);
	
	} 
	
	const l = getLabel('2022-01-15');

 const metdata = {};
 const dataarr = [];
 
 metrics.forEach((m, i) => {
	const label = getLabel(m.date);
	if (!metdata.hasOwnProperty(label)) {
		metdata[label] = {sum: 0, count: 0, value: 0};
		dataarr.push(label);
	}
	metdata[label].sum += m.weight;
	metdata[label].count++;
	metdata[label].value = metdata[label].sum / metdata[label].count;
	if (type === 'day') {
		const startAcc = i < 7 ? 0 : i - 7;
		let accCount = 0;
		let accSum = 0;
		for (let j=startAcc; j<=i; j++) {
			accSum += metdata[dataarr[j]].value; 
			accCount++;
		}
		metdata[label].periodAvarage = accSum / accCount;
	}
	
	
 });

	const labels = dataarr.map(label => {return label}); 
	const values = dataarr.map(label => {return metdata[label].value});	
	
	const datasets = [
	    {
	      label: 'Örjan',
	      data: values,
	      borderColor: 'rgb(255, 99, 132)',
	      backgroundColor: 'rgba(255, 99, 132, 0.5)',
		  lineTension: 0.3
	    },
	  ];
	
	if (type === 'day') {
		const periodData = dataarr.map(label => {return metdata[label].periodAvarage});	
		datasets.push({
		      label: 'Rullande 7 dagar medel',
		      data: periodData,
		      borderColor: 'rgb(53, 162, 235)',
		      backgroundColor: 'rgba(53, 162, 235, 0.5)',
		      lineTension: 0.3
		    }			
		);
	}
	
	const chartdata = {
	  labels,
	  datasets: datasets
	};

	const handleFromChange = (event) => {	
		setFrom(event.target.value);
	}
	
	const handleToChange = (event) => {	
		setTo(event.target.value);
	}

	const changeType = (type) => {	
		setType(type);
		const date = new Date();
		if (type === 'day')
			date.setMonth(date.getMonth() - 3);
		if (type === 'week') {
			date.setMonth(date.getMonth() - 12);
			const dayfix = -1*(date.getDay()+6)%7; // first day of week 
			date.setDate(date.getDate() + dayfix);
		}
		if (type === 'month') {
			date.setMonth(date.getMonth() - 72);
			date.setDate(1);
		}
		
		setFrom(date.toISOString().substring(0, 10));
	}
	

	return (
		<>
		    <BreadCrumbs path={[{link: "/", title:"Hem"}, {link: "/weight", title:"Hälsovärde"}]} title="Viktgraf" />
			<input type="date" name="from"  value={from}  onChange={handleFromChange} /> - <input type="date" name="to"  value={to}  onChange={handleToChange} /><br/>
			<button className={type === 'day' ? 'buttonselected' : ''} type="button" name="setDay" onClick={() => {changeType('day')}}>Dag</button>
			<button className={type === 'week' ? 'buttonselected' : ''} type="button" name="setDay" onClick={() => {changeType('week')}}>Vecka</button>
			<button className={type === 'month' ? 'buttonselected' : ''} type="button" name="setDay" onClick={() => {changeType('month')}}>Månad</button>
            <Line options={options} data={chartdata} />
		</>
	);
}

export default WeightChart;