import React from 'react';
import { Routes, Route } from 'react-router-dom';

import SignIn from './SignIn';
import StartPage from './StartPage';
import Invite from './Invite';
import Version from './Version';
import WeightInput from './WeightInput/WeightInput';
import WeightChart from './WeightChart/WeightChart';
import Refuel from './Refuel/Refuel';
import ListRefuels from './Refuel/ListRefuels';
import SunChartDataInput from './SunChart/SunChartDataInput';
import SunChartStats from './SunChart/SunChartStats';


import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider
} from "@apollo/client";

const Main = ({setIsSignedIn, setPwd, token, guid, server, setServer, version, setVersion}) => {
	
  const client = new ApolloClient({
	  uri: server+'pimcore-graphql-webservices/home?apikey='+token+'&guid='+guid,
	  cache: new InMemoryCache()
  });

  return (
	<div>
		
		<ApolloProvider client={client}>
			<Version version={version} setVersion={setVersion} />
		    <Routes> {/* The Switch decides which component to show based on the current URL.*/}
			  <Route path='' element={<StartPage guid={guid} />} />
	          <Route path='weight' element={<WeightInput guid={guid} />} />
			  <Route path='weightchart' element={<WeightChart guid={guid} />} />
			  <Route path='refuel' element={<Refuel guid={guid} />} />
			  <Route path='refuel/list' element={<ListRefuels />} />
			  <Route path='sunchart' element={<SunChartDataInput />} />
			  <Route path='sunchart/stats' element={<SunChartStats server={server} apikey={token} />} />
			  <Route path='invite' element={<Invite setPwd={setPwd} setIsSignedIn={setIsSignedIn} setServer={setServer} />} />
		      <Route path='signin' element={<SignIn setIsSignedIn={setIsSignedIn} token={token} setPwd={setPwd} pwd={guid} server={server} setServer={setServer}/>} />
		    </Routes>
		</ApolloProvider>
	</div>
  );
}

export default Main;