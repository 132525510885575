import React, { useState }  from 'react';

import {
	useQuery,
	gql
} from "@apollo/client";

import BreadCrumbs from "../BreadCrumbs/BreadCrumbs";
import moment from 'moment';
import './SunChart.css';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const PLANT = 'orjan';

const MONTHS = ['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December'];

	const METRICS_QUERY = gql`
		     query getDateMetricListing($filter: String!)  {
			  getDateMetricListing(filter: $filter, sortBy: "date", sortOrder: "ASC") {
				edges {
			      node {
			        id
					key
			        date
			        metric
			      }
			    }
			  }
			}
		    `;

const charts = {
	day: {name: 'Dagsvy',
		  dateFormat: 'MM-DD'},
	week: {name: 'Veckovy',
		  dateFormat: 'WW'},
	month: {name: 'Månadsvy',
		  dateFormat: 'MM'},
	year: {name: 'Årsvy',
		  dateFormat: null},
	total: {name: 'Total',
		  dateFormat: null},
}


function SunChartStats() {
	
	
	let metrics = [];	
		
	const [type, setType] = useState('week');
	
	const getDates = () => {
		const fromDate = new Date();
		let toDate = new Date();
		
		if (type === 'day') {
			const month = fromDate.getMonth() === 0 ? 0 : fromDate.getMonth() -1;
			fromDate.setMonth(month);
			fromDate.setDate(1);
			
			toDate = new Date(toDate.getFullYear(), month + 1, 0, 23, 59, 59);
		}	
		else {
			
			fromDate.setMonth(0);
			fromDate.setDate(1);
			toDate = new Date(toDate.getFullYear(), 11, 31, 23, 59, 59);
		}
		fromDate.setHours(0);
		fromDate.setMinutes(0);
		fromDate.setSeconds(0);
		
		fromDate.setFullYear(toDate.getFullYear() - 3);
		
		return {fromDate: fromDate, toDate: toDate};
	}	
	
	
	
	const fromAndToDates = getDates();
	const fromDate = fromAndToDates.fromDate;
	const toDate = fromAndToDates.toDate;
	
	
	
	const [from, setFrom] = useState(fromDate);
	const [to, setTo] = useState(toDate);
	
	
	const path = "/sunchart/"+PLANT+"/%";
	const filter  =  '{"o_path": {"$like" : "'+path+'"}, "$and": [{"date" : {"$gte" : "'+Math.floor(from.getTime() / 1000)+'"}}, {"date" : {"$lte" : "'+Math.floor(to.getTime() / 1000)+'"}}]}';
	
	
	const { loading, error, data, refetch } = useQuery(METRICS_QUERY, {
		variables: { filter:  filter},
		onCompleted: data => {

		}
	});
	
	
	
	
	if (data) {
		metrics = data.getDateMetricListing.edges.map(metric => {return {id: metric.node.id, key: metric.node.key, date: metric.node.date.substring(0,10), metric: metric.node.metric };});
	}
	
	
 const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Producerat - ' + charts[type].name,
    },
  },

};


const getLabel = (dateString) => {	
		const d = new Date(dateString);
		const typeData = charts[type];
		if (!typeData) {
			return '';
		}
		if (type == 'week') {
			const w = moment(d).isoWeek();
			const dayfix = w == 1 ? (7-d.getDay())%7 : -1*(d.getDay()+6)%7; // first day of week if not week 1, else last day of week, to get correct year
			d.setDate(d.getDate() + dayfix);
		}
		
		return moment(d).format(typeData.dateFormat);
	
	} 

 const metdata = {};
 const dataarr = [];
 
	metrics.forEach((m, i) => {
		const label = getLabel(m.date);
		const year = m.date.substring(0, 4);
		if (!metdata.hasOwnProperty(year)) {
			metdata[year] = {};
		}
		if (!metdata[year].hasOwnProperty(label)) {
			metdata[year][label] = 0;
			if (!dataarr.includes(label)) {
				dataarr.push(label);
			}
		}
		metdata[year][label] += m.metric;
	});

	const labels = dataarr.sort((a, b) => {return a - b}); 
	
	const datasets = [];
	const COLORS = ['#e74c3c', '#af7ac5', '#5dade2', '#76d7c4', '#f7dc6f', '#dc7633', '#34495e'];
	let colorcount = 0;
	
	for (const year in metdata) {
		const values = labels.map(label => {return metdata[year].hasOwnProperty(label) ? metdata[year][label] : 0});
		datasets.push({
			label: year,
	      	data: values,
	      	backgroundColor: COLORS[colorcount++ % COLORS.length],
		});
	}
	
	const chartdata = {
	  labels,
	  datasets: datasets
	};

	const handleFromChange = (val) => {	
		setFrom(val);
	}
	
	const handleToChange = (val) => {	
		setTo(val);
	}

	const changeType = (type) => {	
		setType(type);
		/*
		const date = new Date();
		if (type === 'day')
			date.setMonth(date.getMonth() - 3);
		if (type === 'week') {
			date.setMonth(date.getMonth() - 12);
			const dayfix = -1*(date.getDay()+6)%7; // first day of week 
			date.setDate(date.getDate() + dayfix);
		}
		if (type === 'month') {
			date.setMonth(date.getMonth() - 72);
			date.setDate(1);
		}
		
		setFrom(date.toISOString().substring(0, 10));
		*/
	}
	
	


	return (
		<>
		    <BreadCrumbs path={[{link: "/", title:"Hem"}, {link: "/sunchart", title:"Solenergi"}]} title="Graf" />
				
				
			<button className={type === 'day' ? 'buttonselected' : ''} type="button" name="setDay" onClick={() => {changeType('day')}}>Dag</button>
			<button className={type === 'week' ? 'buttonselected' : ''} type="button" name="setDay" onClick={() => {changeType('week')}}>Vecka</button>
			<button className={type === 'month' ? 'buttonselected' : ''} type="button" name="setDay" onClick={() => {changeType('month')}}>Månad</button>
			<br/>
			Från:
			<select value={fromDate.getMonth()} onChange={(event) => handleFromChange(event.target.value)}>
				 {MONTHS.map((v, i) => (<option key={i} value={i} >{v}</option>))}
			</select>
			Till:
			<select value={toDate.getMonth()} onChange={(event) => handleToChange(event.target.value)}>
				 {MONTHS.map((v, i) => (<option key={i} value={i} >{v}</option>))}
			</select>
				
			<Bar options={options} data={chartdata} />
		</>
	);
}

export default SunChartStats;