import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';


const APP_VERSION = 'app_version';

const READ_CONFIG_QUERY = gql`
 query appVersionQuery($fullpath: String!)  {
  appVersionQuery(fullpath: $fullpath) {
    app_version
  }
}
`;



function Version({ version, setVersion }) {

	useQuery(READ_CONFIG_QUERY, {
		variables: { fullpath: '/home' },
		pollInterval: 1800000,
		onCompleted: data => {
			if (version !== data.appVersionQuery.app_version) {
				localStorage.setItem(APP_VERSION, data.appVersionQuery.app_version);
				window.location.reload();
			}
		}
	});


	return null;
}


export default Version;
