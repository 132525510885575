import React  from 'react';



import {
	useQuery,
	gql
} from "@apollo/client";

import ClipLoader from "react-spinners/ClipLoader";
import { Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

	const METRICS_QUERY = gql`
		     query getHealthMetricsListing($filter: String!)  {
			  getHealthMetricsListing(filter: $filter, first: 31, sortBy: "date", sortOrder: "DESC") {
				edges {
			      node {
			        id
					key
			        date
			        weight
			      }
			    }
			  }
			}
		    `;

function ListWeightInputs({ guid, setSelectedMetric}) {
	
	const [searchParams] = useSearchParams();
	const addedKey = searchParams.get('added');
	let metrics = [];
	const path = "/home/profiles/"+guid+"/healthmetrics/%";
	const filter  = '{"o_path": {"$like" : "'+path+'"}}';
	const { loading, error, data, refetch } = useQuery(METRICS_QUERY, {
		variables: { filter:  filter},
		onCompleted: data => {

		}
	});
	


	if (loading) {
		console.log('loading metrics');
		return (
			<div>
				<ClipLoader loading={loading} size={150} />
			</div>
		);
	}
	if (error) {
		console.log('error loading metrics');
		return `Error! ${error}`;
	}
	if (data) {
		metrics = data.getHealthMetricsListing.edges.map(metric => {return {id: metric.node.id, key: metric.node.key, date: metric.node.date.substring(0,10), weight: metric.node.weight };});
		
		//metrics.sort((a,b) => a.key < b.key ? 1 : a.key > b.key ? -1 : 0);
	}

		if (addedKey) {
			refetch();
		}


	return (
		<>
			<table className="healthmetriclist">
			<tbody>
				{metrics.map(metric => ( <tr className="healthmetricdisplayrow" key={metric.id}>  
												<td >{metric.date}:</td>
												<td align="right">{metric.weight} kg</td>
												<td><Link to={"/weight?metric="+metric.key}>🖊</Link></td>												
											</tr>))}
			</tbody>
		</table>
			
		</>
	);
}

export default ListWeightInputs;