import React  from 'react';



import {
	useQuery,
	gql
} from "@apollo/client";

import ClipLoader from "react-spinners/ClipLoader";
import { Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import BreadCrumbs from "../BreadCrumbs/BreadCrumbs";

	const REFUELS_QUERY = gql`
		     query getRefuelListing($filter: String!)  {
			  getRefuelListing(filter: $filter, sortBy: "mileage__value", sortOrder: "DESC") {
				 edges {
			      node {
			        id
			        date
			        mileage {
			          unit {
			            id
			            abbreviation
			            longname
			          }
			          value
			        }
			        price {
			          unit {
			            id
			            abbreviation
			            longname
			          }
			          value
			          toString
			        }
			        volume {
			          value
			          toString
			        }
			        fuel
			        Comment
			        station {
			          ... on object_FuelStation {
			            key
			            name
			            brand
			          }
			        }
			      }
			    }
			  }
			}
		    `;

function ListRefuels() {
	
	const [searchParams] = useSearchParams();
	const addedKey = searchParams.get('added');
	let refuels = [];
	let presentationlist = [];
	const path = "/refuel/vehicles/Volvo V70/%";
	const filter  = '{"o_path": {"$like" : "'+path+'"}}';
	const { loading, error, data, refetch } = useQuery(REFUELS_QUERY, {
		variables: { filter:  filter},
		onCompleted: data => {

		}
	});
	


	if (loading) {
		console.log('loading refuels');
		return (
			<div>
				<ClipLoader loading={loading} size={150} />
			</div>
		);
	}
	if (error) {
		console.log('error loading refuels');
		return `Error! ${error}`;
	}
	if (data) {
		refuels = data.getRefuelListing.edges.map(refuel => {return { 	
								id: parseInt(refuel.node.id),
						        date: refuel.node.date.substring(0, 10),
						        mileage: refuel.node.mileage.value,
								price: refuel.node.price.value,
						        volume: refuel.node.volume.value,
						        fuel: refuel.node.fuel,
								station: refuel.node.station.id,
								Comment: refuel.node.Comment};});
			
			let next = null;	
			refuels.forEach((refuel, i, all) => {
				const length = next ? next.mileage - refuel.mileage : '';
				const consumption = next ? (10*next.volume/length) : '';
				const unitPrice = (refuel.price/refuel.volume);
				let line =  {
					id: refuel.id,
					date: refuel.date,
					mileage: refuel.mileage,
					fuel: refuel.fuel,
					volume: refuel.volume,
					price: refuel.price,
					length: length,
					consumption: consumption ? consumption.toFixed(2) : '', 
			        unitPrice: unitPrice ? unitPrice.toFixed(2) : '',
					milagePrice: next ? (unitPrice*consumption).toFixed(2) : '', 
				};
				presentationlist.push(line);
				next = refuel; // reverse order in array, next refuel is the previous element in array
			});
			
		
	}

		if (addedKey) {
			refetch();
		}


	return (
		<>
		<BreadCrumbs path={[{link: "/", title:"Hem"}, {link: "/refuel", title:"Tanka"}]} title="Tankningar" />
		<table className="refuellist">
			<tbody>
				<tr> <th>Datum</th> <th>Mätarställning</th> <th>Bränsle</th> <th>Volym</th> <th>Literpris</th> <th>Pris</th> <th>Körsträcka</th> <th>Milpris</th> <th>Förbrukning</th> <th></th> </tr> 
				{presentationlist.map(refuel => ( <tr className="refuelsdisplayrow" key={refuel.id}>  
												<td >{refuel.date}</td>
												<td align="right">{refuel.mileage}</td>
												<td>{refuel.fuel}</td>
												<td align="right">{refuel.volume}</td>
												<td align="right">{refuel.unitPrice}</td>
												<td align="right">{refuel.price}</td>
												<td align="right">{refuel.length}</td>
												<td align="right">{refuel.milagePrice}</td>
												<td align="right">{refuel.consumption}</td>
												
												
												<td><Link to={"/refuel?id="+refuel.id}>🖊</Link></td>												
											</tr>))}
			</tbody>
		</table>
			
		</>
	);
}

export default ListRefuels;