// StationSelector

import React, { useState }  from 'react';

const MATCH_DISTANCE = 0.1;

if (typeof(Number.prototype.toRad) === "undefined") {
	Number.prototype.toRad = function() {
		return this * Math.PI / 180;
	};
}


function getDistance(lat1,lon1,lat2,lon2) {
	const dLat = (lat2-lat1).toRad();
	const dLon = (lon2-lon1).toRad(); 
	const a = Math.sin(dLat/2) * Math.sin(dLat/2) +	Math.cos(lat1.toRad()) * Math.cos(lat2.toRad()) * Math.sin(dLon/2) * Math.sin(dLon/2); 
	var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
	return 6371 * c; // in km
}


function setClosestStation(stations, setClosestStationId) {
	if (navigator.geolocation) {
		navigator.geolocation.getCurrentPosition(

		function(position) {
			
			let shortestValue = MATCH_DISTANCE;
			let closestStationId = null; 
			
			stations.forEach(s => {
				if (!s.location) {
					return;
				}
				s.distance = getDistance(position.coords.latitude, position.coords.longitude, s.location.latitude, s.location.longitude);
				if (s.distance  < shortestValue) {
					shortestValue = s.distance;
					closestStationId = s.id;
				}
			});
			if (closestStationId) {
				setClosestStationId(closestStationId);
			}
		},
		// next function is the error callback
		function(error) {
			switch (error.code) {
			case error.TIMEOUT:
				alert('Timeout');
				break;
			case error.POSITION_UNAVAILABLE:
				alert('Position unavailable');
				break;
			case error.PERMISSION_DENIED:
				alert('Permission denied');
				break;
			case error.UNKNOWN_ERROR:
				alert('Unknown error');
				break;
			}
		});
	} 
	else {
		alert("no geolocation support");
	}
}

function StationSelector({ baseData, state, handleStationChange }) {
	
	

	return (
		<>
		    <select value={state.station} onChange={handleStationChange}>
				<option value="0">Välj</option>
				{baseData.stations.map(s => (<option key={s.id} value={s.id} >{s.brand} {s.name}</option>))}
			</select>

		</>
	);
}





export default StationSelector;
export { setClosestStation};