import React, { useState }  from 'react';

import { useSearchParams } from "react-router-dom";

import NewWeightInput from './NewWeightInput';
import UpdateWeightInput from './UpdateWeightInput';
import ListWeightInputs from './ListWeightInputs';
import BreadCrumbs from "../BreadCrumbs/BreadCrumbs";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLineChart } from '@fortawesome/free-solid-svg-icons'; // chart-line  <i class="fa-solid fa-chart-line"></i>
import { Link } from "react-router-dom";

//	let metric = {};

function WeightInput({ guid }) {
	



	let [searchParams] = useSearchParams();
	const metricKey = searchParams.get('metric');
	const newMetric = metricKey === null;
	
	const [date, setDate] = useState(metricKey);
	const [weight, setWeight] = useState(0);
	const [metric, setMetric] = useState({});
	
	const setSelectedMetric = (m) => {
		setMetric({id: m.id, key: m.key, date: m.date, weight: m.weight});
	}
	

	return (
		<>
		    <BreadCrumbs path={[{link: "/", title:"Hem"}]} title="Hälsovärde" />
			<br/> 
				
			<Link to="/weightchart"><FontAwesomeIcon className="nobreak" icon={faLineChart} /></Link>
			{newMetric ? <NewWeightInput guid={guid} setSelectedMetric={setSelectedMetric} metric={metric}/> : <UpdateWeightInput guid={guid} metric={metric}  setSelectedMetric={setSelectedMetric}/> }
			
			<ListWeightInputs guid={guid} setMetric={setSelectedMetric}/>
		</>
	);
}

export default WeightInput;